import Localizedimage from '../common/localizedimage/localizedimage';
import './footer.scss';
import {useTranslation} from 'react-i18next';

let Footer = () => {
  const {t} = useTranslation();
  return (<footer className="container">
    <div className="footer-row">
      <div className="u-full-width">
        <h2>{t('footer.header')}</h2>
      </div>

    </div>
    <div className="footer-row">
      <div className="one-third column footer-col-img okm">
        <Localizedimage image="OKM_logo" alt="footer.okm.logo_alt"/>
      </div>

      <div className="one-third column footer-col-img csc">
        <Localizedimage image="CSC" alt="footer.csc.logo_alt"/>
      </div>
    </div>

    <div className="footer-row">
      <div className="one-third column footer-col-txt">
        <p>{t('footer.okm.description')}</p>
      </div>
      <div className="one-third column footer-col-txt">
        <p>{t('footer.csc.description')}</p>
        <p>arvo@csc.fi</p>
      </div>
    </div>

  </footer>);
}

export default Footer;
