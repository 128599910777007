let localizations_fi = {
  content: {
    title: {
      title: 'Rekrytointikysely'
    },
    kysely: {
      information: 'Tervetuloa vastaamaan rekrytointikyselyyn!',
      about1: 'Tämä kysely on osa opetus- ja kulttuuriministeriön rekrytointitiedonkeruuta, jonka tarkoituksena on tuottaa vertailutietoa korkeakoulujen tärkeästä voimavarasta, henkilöstöstä.  Kyselyn vastaukset menevät korkeakoululle, joka yhdistää ne tarvittaviin taustatietoihin alla kuvatulla tavalla ja lähettää opetus- ja kulttuuriministeriölle.',
      about2: 'HAKA-kirjautumisen avulla vastaaja tunnistetaan ja hänestä haetaan yksilöivä tunnistetieto vastaajatunnuksen luomiseksi ja sähköisen palautteen antamisen mahdollistamiseksi. Tunniste myös tallennetaan taustatiedoksi, jonka avulla kerätyt tiedot yhdistetään muuhun rekrytointitiedonkeruun aineistoon, esimerkiksi nimike, kansalaisuus maanosatasolla ja henkilöstöryhmä. Tietojen raportoinnissa ja analysoinnissa yksittäisiä henkilöitä ei ole tunnistettavissa.',
      about3: 'Työsuhteen päättymisen jälkeen Haka-kirjautuminen ei ole enää mahdollista. Tällöin organisaation Arvo-vastuuhenkilön on mahdollista luoda vastaajalle henkilökohtainen linkki kyselyyn vastaamista varten.',
      privacy: {
        text: 'Lisätietoa: <1>tietosuojailmoitus</1> ja <3>saavutettavuusseloste</3>.',
        privacyLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=44573815',
        accessibilityLink: 'https://wiki.eduuni.fi/display/CscArvo/Arvo+saavutettavuusseloste',
      }
    },
    login: {
      header: 'Haka-kirjautuminen',
      description: 'Vastaa kyselyyn kirjautumalla oman organisaatiosi tunnuksilla:',
      alt: 'Haka-kirjautuminen'
    },
    tulokset: {
      header: 'Kyselyn tulokset',
      results1: 'Kyselyn tuloksista johdettuja tilastoraportteja on avoimesti saatavilla ',
      vipunen_link: 'opetushallinnon tilastopalvelu Vipusessa.'
    }
  },
  footer: {
    header: 'Yhteistyössä:',
    okm: {
      description: 'Opetus- ja kulttuuriministeriö',
      logo_alt: 'Opetus- ja kulttuuriministeriön logo'
    },
    csc: {
      description: 'Arvo - opetushallinnon vaikuttavuustietopalvelu',
      logo_alt: 'CSC - Tieteen tietotekniikan keskuksen logo'
    }
  },
  profiledata: {
    header: 'Tervetuloa vastaamaan rekrytointikyselyyn!',
    about: 'Valitse organisaatio, jonka kyselyyn aiot vastata:',
    about_yamk: 'Palautekyselyyn välitetään taustatiedoiksi koulutus, ammattikorkeakoulu, koulutuksen kunta, tutkinnon aloitusvuosi ja tutkinnon suorituskieli. Henkilöön liittyviä tietoja ei välitetä palautekyselylle.',
    education: 'Tutkintonimike',
    school: 'Ammattikorkeakoulu',
    municipality: 'Kunta',
    startYear: 'Tutkinnon aloitusvuosi',
    language: 'Tutkinnon suorituskieli',
    form_of_education: 'Koulutusmuoto',
    reminder: 'Tarkistathan, että koulutuksesi tiedot ovat oikein. Siirtymällä palautekyselyyn hyväksyt tietojen käyttämisen palautekyselyn taustatietona.',
    survey: 'Siirry palautekyselyyn',
    type: {
      day: 'Päiväopiskelu',
      multi: 'Monimuoto-opiskelu'
    },
    submit: 'Siirry palautekyselyyn'
  },
  errors: {
    title: 'Virhetilanne',
    general_error: 'Valitettavasti järjestelmän toiminnassa tapahtui virhe. Jos ongelma toistuu, ole hyvä ja ota yhteys ylläpitoon arvo@csc.fi',
    haka_error: 'Valitettavasti Haka ei luovuttanut vaadittuja tietoja kirjautumisesi yhteydessä. Ota yhteyttä omaan oppilaitokseesi.',
    virta_error: 'Virhe haettaessa tietojasi VIRTA-opintotietopalvelusta. Jos tilanne toistuu, ota yhteyttä omaan oppilaitokseesi.',
    no_data_in_virta: 'Valitettavasti kirjautumistietosi eivät vastaa VIRTA-opintotietopalvelusta löydettäviä tietoja. Ota yhteyttä omaan oppilaitokseesi.',
    arvo_error: 'Valitettavasti kyselyyn siirtymisessä tapahtui virhe. Jos ongelma toistuu, ole hyvä ja ota yhteyttä ylläpitoon arvo@csc.fi',
    attach_code: 'Liitä viestiin tämä virhekoodi'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Opiskeluoikeutesi ei kuulu HAKA-kirjaumista vastaavaan korkeakouluun.',
    invalid_type: 'Väärä opiskeluoikeuden tyyppi VIRTA-opintotietopalvelussa. Ota yhteyttä omaan oppilaitokseesi.',
    invalid_date: 'VIRTA-opintotietopalvelusta haettujen tietojen mukaan opiskeluoikeutesi ei ole voimassa. Ota yhteyttä omaan oppilaitokseesi.',
    invalid_laajuus: 'Puutteellinen tieto VIRTA-opintotietopalvelussa. Opiskeluoikeuden laajuuden pitää olla yli 0 op. Ota yhteyttä omaan oppilaitokseesi.',
    not_enough_opintosuoritus: 'Sinulla ei ole riittävästi opintopisteitä VIRTA-opintotietopalvelussa. Ota yhteyttä omaan oppilaitokseesi.',
    no_kandi: 'Sinulla ole kandidaatin tutkinnon suoritusmerkintää opintorekisterissä.',
    header: 'Opiskeluoikeuksia ei löydy',
    no_rights_contact_study_office: 'Tiedoillasi ei löytynyt yhtään opiskeluoikeutta VIRTA-opintotietopalvelusta. Ota yhteyttä omaan oppilaitokseesi.',
    some_rights_contact_study_office: 'Tiedoillasi löytyi {{rights_count}} opiskeluoikeutta VIRTA-opintotietopalvelusta, mutta tiedot eivät riitä palautteen antamiseen. Ota yhteyttä omaan oppilaitokseesi.',
    additional_invalid_rights: 'Lisäksi löytyi {{rights_count}} oikeutta, mutta tiedot eivät riitä palautteen antamiseen. Ota yhteyttä omaan oppilaitokseesi.'
  }
};

export default localizations_fi;
