import {useCallback, useEffect, useState} from 'react';
import Localizedimage from '../common/localizedimage/localizedimage'
import request from '../../util/request'
import {handleError} from '../../util/error.js'
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

function selectOppilaitos(event, state, setState) {
  setState(state => ({
    ...state,
    selectedOppilaitos: state.oppilaitokset
      .find(oppilaitos => oppilaitos.oppilaitoskoodi === event.target.value)
  }));
}

async function onSubmit(event, navigate, state, lang) {
  event.preventDefault();
  const kieli = lang;
  let data = {
    oppilaitos: state.selectedOppilaitos.oppilaitoskoodi,
    kieli: kieli && kieli.substring(0, 2),
    tyyppi: 'rekry'
  };
  const registration = await request('/api/rekry/valitse-oppilaitos', {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  if (registration['kysely_url']) {
    window.location = registration['kysely_url'];
  } else {
    handleError(lang, registration, navigate);
  }
}

function headerImages() {
  return(
    <section id="theme">
      <div className="container">
        <div className="row">

          <div className="six columns">
            <div className="u-full-width">
              <div id="logo">
                <Localizedimage image="logo" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default function Userprofile() {
  const [state, setState] = useState({
    oppilaitokset: [],
    selectedOppilaitos: {},
  });
  const {t, i18n} = useTranslation();
  const lang = i18n.language;
  const navigate = useNavigate();
  const onSubmitCallback = useCallback((e) => {
    onSubmit(e, navigate, state, lang);
  }, [lang, navigate, state]);

  useEffect(() => {
    let hasStorage = 'sessionStorage' in window && window.sessionStorage;
    let key = 'opiskeluoikeudet';
    let data = hasStorage ? sessionStorage.getItem(key) : null;

    if (hasStorage && data) {
      let now = new Date();
      let expiration = new Date(data.timestamp);
      expiration.setMinutes(expiration.getMinutes() + 15);
      if (now.getTime() > expiration.getTime()) {
        data = null;
        sessionStorage.removeItem(key);
      }
    }
    async function fetchOpiskeluoikeudet() {
      try {
        const registration = await request('/api/rekry', {
          method: 'post',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }});
        const {kysely_url, oppilaitokset} = registration;

        if (kysely_url) {
          window.location = kysely_url;
        }
        else if (oppilaitokset) {
          setState(state => ({
            ...state,
            oppilaitokset,
            selectedOppilaitos: oppilaitokset.length > 1 ? null : oppilaitokset[0],
          }));
        }
        else {
          handleError(lang, registration, navigate);
        }
      } catch(e) {
        handleError(lang, e.json, navigate);
      }
    }
    if (!state.isInitialised) {
      fetchOpiskeluoikeudet();
    }
  }, [lang, navigate, state.isInitialised]);

  return (
    <div>
      {headerImages()}
      <section id="userprofile">
        <div className="container">
          <div className="row">
            <div className="u-full-width">
              <h1>{t('profiledata.header')}</h1>
            </div>
            { state.oppilaitokset.length > 1 ?
              <div className="u-full-width">
                <p>{t('profiledata.about')}</p>
              </div>
              : <div className="u-full-width">
                <p>{state.selectedOppilaitos[lang]}</p>
              </div>
            }
            <form onSubmit={onSubmitCallback}>
              {(state.oppilaitokset.length > 1) ?
                <div onChange={(event) => selectOppilaitos(event, state, setState)}>
                  {
                    state.oppilaitokset.map(oppilaitos =>
                      <label>
                        <input value={oppilaitos.oppilaitoskoodi}
                               key={oppilaitos.oppilaitoskoodi}
                               type="radio"
                               name="oppilaitokset" />
                        <span className="label-body">{oppilaitos[lang]}</span>
                      </label>
                    )
                  }
                </div>
                : ''
              }
              <div className="u-full-width">
                <button type="submit" disabled={state.selectedOppilaitos == null && state.oppilaitokset.length > 1}>
                  <span>{t('profiledata.submit')}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
