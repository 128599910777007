let localizations_en = {
  content: {
    title: {
      title: 'Recruitment survey'
    },
    kysely: {
      information: 'Welcome to recruitment survey!',
      about1: 'This survey is a part of a data collection carried out by the Ministry of Education and Culture regarding recruitment. The purpose is to produce benchmark data on the most important resource of higher education institutions, namely human resources. The data is collected by higher education institutions and sent to the Ministry of Education and Culture.',
      about2: 'The person is identified using Haka login to create a unique answer identifier and to make electronic feedback possible. The identifier is saved as a background information which is used to associate the data collected with this survey to other background variables (such as title, nationality at continental level, personnel group). Read more in the privacy policy. It will not be possible to identify individuals in the reporting and analysis of the survey results.',
      about3: '',
      privacy: {
        text: 'More information in <1>Privacy policy</1> and <3>Accessibility statement</3>.',
        privacyLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=44573815',
        accessibilityLink: 'https://wiki.eduuni.fi/display/CscArvo/Arvo+Accessibility+Statement',
      }
    },
    login: {
      header: 'Haka-login',
      description: 'Please sign in to the survey by using your own organization\'s credentials:',
      alt: 'Haka login'
    },
    tulokset: {
      header: 'The results',
      results1: 'The statistics are freely available at the ',
      vipunen_link: 'Vipunen statistics services of the educational administration.'
    }
  },
  footer: {
    header: 'In cooperation:',
    okm: {
      description: 'Ministry of Education and Culture',
      logo_alt: 'Ministry of education and culture logo'
    },
    csc: {
      description: 'Arvo - education management information service',
      logo_alt: 'CSC logo'
    }
  },
  profiledata: {
    header: 'Welcome to answer the recruitment survey!',
    about: 'Please select your organization:',
    about_yamk: 'Information on education, university of applied sciences, municipality, start date of the right to study and language of learning is used as survey background information. The collected personal information is not transferred to the feedback system.',
    education: 'Degree title',
    school: 'University of applied sciences',
    municipality: 'Municipality',
    startYear: 'Start date of the right to study',
    language: 'Language of learning',
    form_of_education: 'Mode of education',
    reminder: 'Please confirm the education information is correct. Once you move to the feedback questionnaire, you accept that the information is used as background information.',
    survey: 'Go to the survey',
    type: {
      day: 'Daytime studies',
      multi: 'Multiform studies'
    },
    submit: 'Go to the survey'
  },
  errors: {
    title: 'Error situation',
    general_error: 'Unfortunately an unspecified error occurred. Please try again later and if the error reoccurs, contact arvo@csc.fi',
    haka_error: 'Unfortunately Haka did not provide the required information when you logged in. Contact your own educational institution.',
    virta_error: 'There was an error when retrieving your information from the VIRTA higher education achievement register. If this  situation happens again, contact your own educational institution.',
    no_data_in_virta: 'Unfortunately your login information does not match the information found in the VIRTA higher education achievement register. Contact your own educational institution.',
    arvo_error: 'Unfortunately an error occurred when trying to access the questionnaire. Please try again later and if the error reoccurs, contact arvo@csc.fi',
    attach_code: 'Attach this error code to the message'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Your right to study does not correspond to the HAKA home organisation.',
    invalid_type: 'The type of the right to study is incorrect in the VIRTA higher education achievement register. Contact your own educational institution.',
    invalid_date: 'According to the information retrieved from the VIRTA higher education achievement register, your right to study is not valid. Contact your own educational institution.',
    invalid_laajuus: 'Incomplete information in the VIRTA higher education achievement register. The scope of the right to study must be more than 0 credits. Contact your own educational institution.',
    not_enough_opintosuoritus: 'You don\'t have enough credits in the VIRTA higher education achievement register. Contact your own educational institution.',
    no_kandi: 'Sinulla ole kandidaatin tutkinnon suoritusmerkintää opintorekisterissä.',
    header: 'Rights to study weren\'t found.',
    no_rights_contact_study_office: 'No rights to study were found with your information from the VIRTA higher education achievement register. Contact your own educational institution.',
    some_rights_contact_study_office: 'With your information, {{rights_count}} study rights were found in the VIRTA higher education achievent register, but the information is not sufficient for giving feedback. Contact your own educational institution.',
    additional_invalid_rights: 'In addition, {{rights_count}} rights to study were found without the required information to grant access to the graduate survey. Contact your own educational institution.'
  }
};

export default localizations_en;
