let localizations_sv = {
  content: {
    title: {
      title: 'Rekryteringsenkät'
    },
    kysely: {
      information: 'Välkomna att svara på rekryteringsenkäten',
      about1: 'Denna enkät är en del av undervisnings- och kulturministeriets datainsamling. Syftet med datainsamlingen är att generera kunskap om en viktig resurs vid högskolorna, dvs. personalen. Högskolorna samlar in informationen och skickar den vidare till undervisnings- och kulturministeriets datainsamling.',
      about2: 'Med hjälp av HAKA-inloggning identifieras respondenten för att kunna skapa en unik svarskod. Detta möjliggör ifyllandet av enkäten. Uppgifter som identifierar respondenten ska sparas, och med hjälp av dessa uppgifter förenas enkätens data med vissa bakgrundsvariabler (t.ex. yrkesbeteckning, svarandens nationalitet enligt världsdel, personalgrupp). Se registerbeskrivningen för närmare information. Det går inte att urskilja enskilda personer i rapporteringen eller i analysen av informationen.',
      about3: 'Efter anställningsförhållandet är det inte möjligt att använda HAKA-inloggning. Då skickas en personlig länk till respondenten för att svara på enkäten.',
      privacy: {
        text: 'Utförligare information i <1>dataskyddsbeskrivning</1> och <3>tillgänglighetsutlåtande</3>.',
        privacyLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=44573815',
        accessibilityLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=303291918',
      }
    },
    login: {
      header: 'Logga in',
      description: 'Använd din personliga HAKA-kod för att logga in på enkäten:',
      alt: 'Haka-inloggning'
    },
    tulokset: {
      header: 'Enkätens resultat',
      results1: 'Statistiska rapporter av enkätens resultat finns till allmänt påseende.',
      results2: 'Tack för att du tar dig tid! ',
      vipunen_link: 'via utbildningsförvaltningens statistiktjänst Vipunen. '
    }
  },
  footer: {
    header: 'I samarbete med:',
    okm: {
      description: 'Undervisnings- och kulturministeriet',
      logo_alt: 'Undervisnings- och kulturministeriet logo'
    },
    csc: {
      description: 'Arvo - informationstjänst för effektivitetshantering i utbildningsförvaltning',
      logo_alt: 'CSC logo'
    }
  },
  profiledata: {
    header: 'Välkommen att svara på rekryteringsenkäten!',
    about: ' Välj din organisation:',
    about_yamk: 'Som bakgrundinformation för enkäten används följande uppgifter: utbildning, yrkeshögskola, yrkeshögskolans kommun, inledningsdatum för studierna och språket för examen. Personuppgifter förmedlas inte vidare till enkäten och alla svar behandlas anonymt.',
    education: 'Examensbenämning',
    school: 'Yrkeshögskola',
    municipality: 'Kommun',
    startYear: 'Inledningsdatum för studierna',
    language: 'Språket för examen',
    form_of_education: 'Undervisningsform',
    reminder: 'Var god och kontrollera att uppgifterna om din utbildning är korrekta. Genom att gå vidare till enkäten godkänner du att uppgifterna används som bakgrundsinformation för enkäten.',
    survey: 'Gå till enkäten',
    type: {
      day: 'Dagundervisning',
      multi: 'Flerformsundervisning'
    },
    submit: 'Gå till enkäten'
  },
  errors: {
    title: 'Felsituation',
    general_error: 'Tyvärr uppstod ett ospecificerat fel. Vänligen försök igen senare och om felet återkommer, kontakta arvo@csc.fi',
    haka_error: 'Tyvärr lämnade Haka inte den uppgifter som krävs när du loggade in. Kontakta din egen läroanstalt.',
    virta_error: 'Där var ett fel när din  information hämtades från VIRTA högskoleregister. Om situationen upprepar sig, kontakta din egen läroanstalt.',
    no_data_in_virta: 'Tyvärr stämmer dina inloggningsuppgifter inte med informationen som finns i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    arvo_error: 'Tyvärr enkäten kunde inte öppnas. Vänligen försök igen senare och om felet återkommer, kontakta arvo@csc.fi',
    attach_code: 'Bifoga denna felkod i meddelandet'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Din studierätt tillhör inte den högskola som din HAKA-inloggning ger till känna.',
    invalid_type: 'Studierättens typ är inkorrekt i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    invalid_date: 'Enligt den information som hämtas från studieinformationstjänsten VIRTA är din studierätt inte giltig. Kontakta din egen läroanstalt.',
    invalid_laajuus: 'Ofullständig information i studieinformationstjänsten VIRTA. Omfattningen av studierätten måste vara mer än 0 sp. Kontakta din egen läroanstalt.',
    not_enough_opintosuoritus: 'Du har inte tillräckligt med studiepoäng i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    no_kandi: 'Du saknar registrering om avlagd kandidatexamen i studieregistret.',
    header: 'Inga studierätter hittades.',
    no_rights_contact_study_office: 'Inga studierätter hittades med dina uppgifter från studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    some_rights_contact_study_office: 'Med dina uppgifter hittades {{rights_count}} studierätter i studieinformationstjänsten VIRTA, men informationen räcker inte för att ge respons. Kontakta din egen läroanstalt.',
    additional_invalid_rights: 'Dessutom hittades {{rights_count}} studierätter, men informationen räcker inte för att ge respons. Kontakta din egen läroanstalt.'
  }
};

export default localizations_sv;
