import {useTranslation} from 'react-i18next';

import Localizedimage from './common/localizedimage/localizedimage';
import {useParams} from 'react-router-dom';

export default function Error() {
  let {status, sessionid} = useParams();
  status = status ? status : 'general_error';
  const {t} = useTranslation();
  const showSessionId = sessionid && (status === 'general_error' || status === 'arvo_error');

  return (
    <div>
      <section id="theme">
        <div className="container">
          <div className="row">

            <div className="six columns">
              <div className="u-full-width">
                <div id="logo">
                  <Localizedimage image="logo" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <h1>{t('errors.title')}</h1>
            <p>{t('errors.'+ status)}</p>
            {showSessionId ?
              <p>{t('errors.attach_code')}: <strong>{sessionid}</strong></p> : ''
            }
          </div>
        </div>
      </section>

    </div>
  )
}
